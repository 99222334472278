.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.content {
  height: 375px;
  width: 225px;
  background: white;
}

.wrapper{
  height: 100%;
}

.phone{
  max-width: 100%;
  height: 100%;
  /* max-height: 375px; */
  overflow-x: hidden;
  overflow-y: auto;
}

#home{
  z-index: 100;
  &:hover{
    color: grey;
  }
}

.selector{
  z-index: 100;
  position: absolute;
  top: 50vh;
  color: white;
  scale: 3;
  &:hover{
    color: grey;
    cursor: pointer;
  }
}

.career-description{
  z-index: 99;
  position: absolute;
  top: 5vh;
  right: 10vw;
  left: 65vw;
  cursor: default;
  user-select: none;
  pointer-events: none;
  color: black;

  & a{
    pointer-events: all;
    cursor: pointer;
  }
}

.career-description-mobile{
  z-index: 99;
  position: absolute;
  top: 5vh;
  right: 5vw;
  left: 5vw;
  cursor: default;
  user-select: none;
  pointer-events: none;
  color: black;
  font-size: small;

  & a{
    pointer-events: all;
    cursor: pointer;
  }
}