.screen{
    height: 785px;
    width: 1200px;
    padding: 5px;
    padding-left: 15px;
}

.header{
    height: 5%;
    margin-bottom: 1%;
}

.headerText{
    text-align: center;
    text-decoration: underline;
    background-color: rgb(192, 192, 192);
    margin-left: 5%;
    margin-right: 5%;
    border-radius: 5px;

    &.active{
        color: blue;
    }
}

.chirpstack{
    height: 94%;
    max-height: 94%;
}

.sidebar{
    min-height: 94%;
    border: 0;
    border-right: 2px;
    border-style: solid;
    display: flex;
    flex-direction: column;

    .profile{
        margin-top: auto;
        text-align: center;
        background-color: rgb(162, 179, 194);
        margin-right: 20%;
        margin-left: 20%;
        border-radius: 5px;
    }
}

.sideNav{
    margin-top: 20%;
    text-align: right;

    &.active{
        font-weight: bold;
    }
}

.main{
    height: 94%;
    max-height: 94%;
    margin-right: 5%;

    .title{
        text-align: center;
        font-weight: bold;
        font-style: oblique;
    }

    .stream{
        height: 90%;
        max-height: 90%;
        overflow-y: auto;

        .reading{
            height: 7%;
            display: flex;
            flex-direction: row;
            border: solid 2px;
            margin-bottom: 1px;
            border-radius: 5px;
            border-color: lightgray;

            .name{
                margin-left: 2%;
                margin-top: auto;
                margin-bottom: auto;
                text-align: left;
                font-weight: bold;
            }

            .value{
                margin-top: auto;
                margin-bottom: auto;
                text-align: right;
            }

            &.readingError{
                border-color: red;
            }
        }
    }
}