/* Dropup Button */
.dropbtn {
    background-color: #3498DB;
    color: white;
    padding: 8px;
    font-size: 8px;
    border: none;
  }
  
  /* The container <div> - needed to position the dropup content */
  .dropup {
    position: relative;
    display: inline-block;
    margin-left: 70%;
  }
  
  /* Dropup content (Hidden by Default) */
  .dropup-content {
    display: none;
    position: absolute;
    bottom: 25px;
    right: 0px;
    background-color: #f1f1f1;
    min-width: 160px;
    box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
    z-index: 1;
  }
  
  /* Links inside the dropup */
  .dropup-content div {
    color: black;
    padding: 6px 0px;
    text-decoration: none;
    display: block;
    cursor: pointer;
  }
  
  /* Change color of dropup links on hover */
  .dropup-content div:hover {background-color: #ddd}
  
  /* Change the background color of the dropup button when the dropup content is shown */
  .dropup:hover .dropbtn {
    background-color: #2980B9;
  }