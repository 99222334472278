body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

html,
body,
#root
{
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: ivory;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.background{
  background: linear-gradient(0deg, rgba(0,0,0,1) 21%, rgba(121,43,9,1) 48%, rgba(255,224,166,1) 100%);
}

#myVideo {
  position: fixed;
  right: 0;
  bottom: 0;
  min-width: 100%;
  min-height: 100%;
  margin: auto;
}

video {
  left: 50%;
  min-height: 100%;
  min-width: 100%;
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
  pointer-events: none;
  object-fit: contain;
  width: 100%;
}

.videoInsert{
  background-color: #141414;
}

.loading-screen-active{
  opacity: 1;
  transition: opacity 0.2s;
}

.loading-screen-ended{
  opacity: 0;
  transition: opacity 2s;
}