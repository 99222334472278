.phone{
    font-size: x-small;

    & .header{
        height: 4%;
        background-color: lightgray;
        font-size: x-small;
        display: flex;
        & i{
            margin-left: 5px;
        }
    }
}

.chatbox{
    text-align: center;
    vertical-align: middle;
    padding: 2px;

    & .row div{
        border-style: solid;
        border-radius: 3px;
        border-color: gray;
        background-color: lightblue;
        margin: 1%;
        cursor: pointer;
    }
}

.history{
    height: 80%;
    max-height: 80%;
    overflow-y: auto;
    scrollbar-width: none;
    padding: 2px;

    & div{
        margin-top: 1%;
        max-width: 70%;
        border-radius: 3px;
        & div{
            margin: 2%;
            max-width: 100%;
        }
    }
}

.history::-webkit-scrollbar{
    display: none;
}

.owner{
    margin-left: auto;
    background-color: rgb(0, 122, 197);
    color: white;
}

.recipient{
    background-color: rgb(46, 203, 46);
}

.loader-placeholder{
    width: 8px;
    height: 8px;
    margin: 1px 3px;
    margin-top: 4px;
}

.bouncing-loader {
    display: flex;
    justify-content: center;
    margin-top: 4px;
}

.bouncing-loader > div {
    width: 8px;
    height: 8px;
    margin: 1px 3px;
    border-radius: 50%;
    background-color: #a3a1a1;
    opacity: 1;
    animation: bouncing-loader 0.6s infinite alternate;
}

@keyframes bouncing-loader {
    to {
        opacity: 0.1;
        transform: translateY(-8px);
    }
}

.bouncing-loader > div:nth-child(2) {
    animation-delay: 0.2s;
}

.bouncing-loader > div:nth-child(3) {
    animation-delay: 0.4s;
}

#home{
    position: absolute;
    top: 0px;
    /* width: 100%; */
    margin-left: 1%;
    margin-top: 1%;
    font-size: x-large;
    color: white;
    z-index: 1;
    cursor: pointer;
}